import React from "react";

const ReportColumns = (taxName, tax) => {
  return [
    {
      title: "Produkt",
      width: "18%",
      key: "name",
      dataIndex: "name"
    },
    {
      title: "Antall",
      width: "15%",
      key: "quantity",
      dataIndex: "quantity"
    },
    {
      title: `Mva ${tax}%`,
      width: "20%",
      key: "Mva25",
      render: (text, record) => {
        return `Kr.${(
          parseFloat(record.price) -
          parseFloat(record.price) / parseFloat((100 + Number(tax)) / 100)
        ).toFixed(2)}`;
      }
    },
    {
      title: "Nett Sum(Kr.)",
      width: "20%",
      key: "netsum",
      render: (text, record) => {
        return `Kr.${(
          parseFloat(record.price) -
          (parseFloat(record.price) -
            parseFloat(record.price) / parseFloat((100 + Number(tax)) / 100))
        ).toFixed(2)}`;
      }
    },
    {
      title: `Total(Kr.) - Rabatt(${taxName})`,
      width: "30%",
      key: "Totall",
      render: (text, record) => {
        return `Kr.${record.price.toFixed(2)}`;
      }
    }
  ];
};
const summaryColumns = (totalQuantity, total, discountPrice, tax) => {
  return [
    {
      title: "Navn",
      width: "18%",
      render: () => <strong>Total</strong>
    },
    {
      title: "Total Antall",
      width: "15%",
      render: () => `${totalQuantity}`
    },
    {
      title: "Total Mva",
      width: "20%",
      render: () =>
        `Kr.${(
          Number(total) -
          Number(discountPrice) -
          (Number(total) - Number(discountPrice)) /
            parseFloat((100 + Number(tax)) / 100)
        ).toFixed(2)}`
    },
    {
      title: "Nett Total",
      width: "20%",

      render: () =>
        `Kr.${(
          (Number(total) - Number(discountPrice)) /
          parseFloat((100 + Number(tax)) / 100)
        ).toFixed(2)}`
    },
    {
      title: "Total",
      width: "30%",
      render: () =>
        `Kr.${total && (Number(total) - Number(discountPrice)).toFixed(2)}`
    }
  ];
};
const summaryData = () => {
  return [
    {
      key: "-1",
      render: () => "test"
    }
  ];
};
const groupeDiscountColumn = () => {
  return [
    {
      title: "Faktura Nr.",
      width: "20%",
      key: "invoiceNumber",
      dataIndex: "invoiceNumber"
    },
    {
      title: "Rabatt(%)",
      width: "20%",
      key: "discount",
      dataIndex: "discount"
    },
    {
      title: "Rabatt Pris",
      width: "20%",
      key: "discountPrice",
      render: (text, record) => {
        return `${record.discountPrice.toFixed(2)}`;
      }
    }
  ];
};
const discountSummary = (discountPrice) => {
  return [
    {
      title: "Total",
      width: "20%",
      key: "1",
      render: () => <strong>Total</strong>
    },
    {
      title: "",
      width: "20%",
      key: "2"
    },
    {
      title: "Rabatt Pris",
      width: "20%",
      key: "discountPriceTotal",
      render: () => `${discountPrice.toFixed(2)}`
    }
  ];
};
const driveoutColumns = () => {
  return [
    {
      title: "Total Ordre",
      dataIndex: "dcount",
      width: "10%",
      key: "dcount"
    },
    {
      title: "Utkjøring Mva",
      dataIndex: "driveoutTax",
      width: "10%",
      key: "driveoutTax"
    },
    {
      title: "Utkjøring Pris",
      dataIndex: "driveoutCharge",
      width: "15%",
      key: "driveoutCharge"
    },

    {
      title: "Total Utkjøring Pris",
      dataIndex: "totalDcharge",
      width: "13%",
      key: "totalDcharge"
    }
  ];
};
const couponColumns = () => {
  return [
    {
      title: "Total order",
      dataIndex: "totalCount",
      width: "10%",
      key: "totalCount"
    },
    // {
    //   title: "Coupon Mva",
    //   dataIndex: "taxAmount",
    //   width: "10%",
    //   key: " taxAmount"
    // },
    // {
    //   title: "Coupon Pris",
    //   dataIndex: "afterTax",
    //   width: "15%",
    //   key: "afterTax"
    // },
    {
      title: "Total Coupon Amount",
      dataIndex: "totalCouponValue",
      width: "10%",
      key: "coupanValue"
    }
    // {
    //   title: "reamainigValue",
    //   dataIndex: "reamainigValue",
    //   width: "15%",
    //   key: "reamainigValue"
    // },
  ];
};
const reserveTableColumns = () => {
  return [
    {
      title: "Total order",
      dataIndex: "totalCount",
      width: "10%",
      key: "totalCount"
    },
    // {
    //   title: "Coupon Mva",
    //   dataIndex: "taxAmount",
    //   width: "10%",
    //   key: " taxAmount"
    // },
    // {
    //   title: "Coupon Pris",
    //   dataIndex: "afterTax",
    //   width: "15%",
    //   key: "afterTax"
    // },
    {
      title: "Total  Amount",
      dataIndex: "totalAmount",
      width: "10%",
      key: "totalAmount"
    }
    // {
    //   title: "reamainigValue",
    //   dataIndex: "reamainigValue",
    //   width: "15%",
    //   key: "reamainigValue"
    // },
  ];
};
const driveoutDataSource = ({
  driveoutTax,
  driveoutCharge,
  totalDcharge,
  count
}) => {
  return [
    {
      key: "1",
      dcount: count,
      driveoutTax: `Kr. ${driveoutTax}`,
      driveoutCharge: `Kr. ${driveoutCharge}`,
      totalDcharge: `Kr. ${totalDcharge}`
    }
  ];
};

const couponDataSource = ({
  reamainigValue,
  totalCouponValue,
  afterTax,
  taxAmount,
  taxValue,
  totalCount
}) => {
  return [
    {
      reamainigValue: `Kr. ${reamainigValue}`,
      totalCouponValue: `Kr. ${totalCouponValue}`,
      taxAmount: `Kr. ${taxAmount}`,
      afterTax: `Kr. ${afterTax}`,
      totalCount: totalCount
    }
  ];
};
const reserveTableDataSource = ({ totalAmount, totalCount }) => {
  return [
    {
      totalAmount: `Kr. ${totalAmount}`,
      totalCount: totalCount
    }
  ];
};
const unpaidDataColumns = () => {
  return [
    {
      title: "Faktura Nr.",
      width: "45%",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber"
    },
    {
      title: "Total",
      width: "45%",
      dataIndex: "taxPrice",
      key: "taxPrice"
    }
  ];
};
const unpaidSummaryColumns = (totalUnpaid) => {
  return [
    {
      title: "Navn",
      width: "45%",
      render: () => <strong>Total</strong>
    },
    {
      title: "Total",
      width: "45%",
      render: () => `Kr.${totalUnpaid.toFixed(2)}`
    }
  ];
};
const employeesTipColumn = () => {
  return [
    {
      title: "Navn",
      width: "20%",
      key: "firstName",
      dataIndex: "firstName",
      render: (text, record) => {
        return `${record.firstName}`;
      }
    },
    {
      title: "Email",
      width: "20%",
      key: "email",
      dataIndex: "email"
    },
    {
      title: "Cash",
      width: "20%",
      key: "cash",
      dataIndex: "cash",
      render: (text, record) => `Kr.${record.cash.toFixed(2)}`
    },
    {
      title: "Bank",
      width: "20%",
      key: "bank",
      dataIndex: "bank",
      render: (text, record) => `Kr.${record.bank.toFixed(2)}`
    },
    {
      title: "Total",
      width: "30%",
      key: "total",
      dataIndex: "total",
      render: (text, record) => `Kr.${record.total.toFixed(2)}`
    }
  ];
};
const tipSummaryColumns = (tipTotal) => {
  return [
    {
      title: "Name",
      width: "20%",
      render: () => <strong>Total</strong>
    },
    {
      title: "email",
      width: "20%",
      render: () => <strong> </strong>
    },
    {
      title: "cash",
      width: "20%",
      render: () => <strong> </strong>
    },
    {
      title: "bank",
      width: "20%",
      render: () => <strong> </strong>
    },
    {
      title: "Total",
      width: "30%",
      render: () => `Kr.${tipTotal}`
    }
  ];
};

const employeesRefundColumn  = () => {
  return [
    // {
    //   // userEmail
    //   title: "Invoice Number ",
    //   width: "20%",
    //   key: "invoiceNumber",
    //   // dataIndex: "email"
    //   render: (text, record) => {
    //     return `${record.invoiceNumber ? record.invoiceNumber : "-----"}`;
    //   }
    // },
    // {
    //   // userEmail
    //   title: "User Email ",
    //   width: "20%",
    //   key: "userEmail",
    //   // dataIndex: "email"
    //   render: (text, record) => {
    //     return `${record.userEmail ? record.userEmail : "No email"}`;
    //   }
    // },
    {
      title: "Customer Email",
      width: "33%",
      key: "email",
      // dataIndex: "email"
      render: (text, record) => {
        return `${record.email ? record.email : "No email"}`;
      }
    },
    {
      title: "Customer Phone",
      width: "33%",
      key: "customerPhone",
      dataIndex: "customerPhone",
      render: (text, record) => {
        return `${record.customerPhone ? record.customerPhone : "-"}`;
      }
    },
    {
      title: "Refund Amount",
      width: "33%",
      key: "refundAmt",
      dataIndex: "refundAmt",
      render: (text, record) => `Kr.${record.refundAmt.toFixed(2)}`
    }
  ];
};

const refundSummaryColumns= (totalRefund) => {
  return [
    {
      title: "userEmail",
      width: "70%",
      render: () => <strong>Total</strong>
    },
    {
      title: "Total Refund ",
      width: "30%",
      render: (text, record) => `Kr.${totalRefund.toFixed(2)}`
    }
  ];
};

export {
  ReportColumns,
  summaryColumns,
  summaryData,
  groupeDiscountColumn,
  discountSummary,
  driveoutColumns,
  couponDataSource,
  couponColumns,
  driveoutDataSource,
  unpaidDataColumns,
  unpaidSummaryColumns,
  employeesTipColumn,
  tipSummaryColumns,
  reserveTableColumns,
  reserveTableDataSource,
  employeesRefundColumn,
  refundSummaryColumns
};
