import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Home from "../../Home";
import { Icon as RIcon } from "react-icons-kit";
import { dollar } from "react-icons-kit/fa/dollar";
import { fileText } from "react-icons-kit/fa/fileText";
import { bank } from "react-icons-kit/fa/bank";
import { DatePicker, Button, Statistic, Table, Spin } from "antd";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import {
  getGroupReport,
  printGroupReport
} from "../../../action/arkiveActions";
import ReportList from "./ReportList";
import TableSum from "../../../containers/TableSum/TableSum";
import {
  driveoutColumns,
  driveoutDataSource,
  unpaidDataColumns,
  unpaidSummaryColumns,
  summaryData,
  employeesTipColumn,
  tipSummaryColumns,
  couponColumns,
  couponDataSource,
  reserveTableColumns,
  reserveTableDataSource,
  employeesRefundColumn,
  refundSummaryColumns
} from "./TableColumns";
import moment from "moment";
import { Row, Col, Card } from "react-bootstrap";
const { MonthPicker } = DatePicker;

const Index = (props) => {
  let [printData, setPrintData] = useState({});
  let [printLoader, setPrintLoader] = useState(false);
  const {
    driveOutCharges,
    onlineTotal,
    onlineVippsTotal,
    report,
    tips,
    totalBank,
    totalBoxOpen,
    totalCash,
    totalCredit,
    totalTip,
    unPaid,
    totalCoupan,
    treeDriveTotal,
    couponData,
    reserveTableData,
    treeDriveVippsTotal,
    OrcTotal,
    totalRefund,
    refundData
  } = props.groupReport;
  useEffect(() => {
    handleFilter(moment(new Date()), "d");
  }, []);
  let restCouponAmt=couponData.totalCouponValue?couponData.totalCouponValue:0
  const finalTotal =
    Number(totalBank) +
    Number(totalCash) +
    Number(onlineVippsTotal) +
    Number(onlineTotal) +
    Number(totalCredit) -
    Number(totalTip) +
    Number(treeDriveTotal) +Number(treeDriveVippsTotal)+Number(OrcTotal)+
    Number(totalCoupan) -Number(restCouponAmt) - Number(totalRefund)
  // const handleFilter = (date, type) => {
  //   let postdata = {};
  //   switch (type) {
  //     case "m":
  //       postdata = {
  //         startDate: moment(date).startOf("month").set({
  //           hour: 4,
  //           minute: 0,
  //           second: 0
  //         }),
  //         endDate: moment(date).endOf("month").add(1, "days").set({
  //           hour: 3,
  //           minute: 59,
  //           second: 59
  //         })
  //       };
  //       break;
  //     case "d":
  //       postdata = {
  //         startDate: moment(date).set({
  //           hour: 4,
  //           minute: 0,
  //           second: 0
  //         }),
  //         endDate: moment(date).add(1, "days").set({
  //           hour: 3,
  //           minute: 59,
  //           second: 59
  //         })
  //       };
  //   }
  //   let printData = {
  //     ...postdata,
  //     type
  //   };
  //   setPrintData(printData);
  //   props.getGroupReport(postdata);
  // };

  const handleFilter = (date, type) => {
    let postdata = {};

    switch (type) {
      case "m":
        postdata = {
          startDate: moment(date).startOf("month").add(1, "days"),
          endDate: moment(date).endOf("month").add(1, "days")
        };
        break;
      case "d":
        postdata = {
          startDate: moment(date),
          endDate: moment(date).add(1, "days")
        };
    }
    let printData = {
      ...postdata,
      type
    };
    setPrintData(printData);
    props.getGroupReport(postdata);
  };
  const unPaidList = (unPaid) => {
    let totalUnpaid = 0;
    unPaid.forEach((element) => {
      totalUnpaid += Number(element.taxPrice);
    });
    return (
      <TableSum
        columns={unpaidDataColumns()}
        summaryColumns={unpaidSummaryColumns(totalUnpaid)}
        data={unPaid}
        summaryData={summaryData()}
        pagination={false}
      />
    );
  };
  const tipList = (tipsData) => {
    let tipsVisible = tipsData.filter((tip) => {
      return tip.total > 0;
    });
    return (
      <TableSum
        columns={employeesTipColumn()}
        summaryColumns={tipSummaryColumns(totalTip)}
        // data={tipsData}
        data={tipsVisible}
        summaryData={summaryData()}
      />
    );
  };

  const refundList= (refundData) => {
    let refundVisible = refundData.filter((refund) => {
      return refund.refundAmt > 0;
    });
    return (
      <TableSum
        columns={employeesRefundColumn()}
        summaryColumns={refundSummaryColumns(totalRefund)}
        // data={tipsData}
        data={refundVisible}
        summaryData={summaryData()}
      />
    );
  };
  const printGPReport = () => {
    if (!printLoader) {
      setPrintLoader(true);
      props.printGroupReport(printData).then((res) => setPrintLoader(false));
    }
  };

  const VippsCard = () => {
    return (
      <>
        <Col span={8}>
          <Card className="gp-box-shadow">
            <Card.Body>
            <Statistic
  value={(Number(onlineVippsTotal) + Number(treeDriveVippsTotal)).toFixed(2)}
  title="VIPPS"
  valueStyle={{ color: "#3f8600", fontSize: 30 }}
  // prefix={<YourCustomIcon />} // Replace with the desired icon component
  prefix={<span style={{ paddingRight:"10px",fontSize: '16px' }}>NOK</span>}
/>

            </Card.Body>
          </Card>
        </Col>
      </>
    );
  };

  const CouponCard = () => {
    return (
      <>
        <Col span={12}>
          <Card className="gp-box-shadow">
            <Card.Body>
              <Statistic
                title="COUPAN"
                precision={2}
                valueStyle={{ color: "#3f8600", fontSize: 30 }}
               // prefix={<RIcon size={48} icon={dollar} />}
               prefix={<span style={{ paddingRight:"10px",fontSize: '16px' }}>NOK</span>}
                value={totalCoupan}
              />
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  };

  const TreeDriveCard = () => {
    return (
      <>
        <Col span={12}>
          <Card className="gp-box-shadow">
            <Card.Body>
              <Statistic
                //title="Tree Drive"
                title="EzyGo"
                precision={2}
                valueStyle={{ color: "#3f8600", fontSize: 30 }}
                // prefix={<RIcon size={48} icon={dollar} />}
                prefix={<span style={{ paddingLeft:"20px",fontSize: '16px' }}>NOK</span>}
                value={treeDriveTotal}
              />
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  };

  const TotallCard = () => {
    return (
      <>
        <Col span={8}>
          <Card className="gp-box-total">
            <Card.Body>
              <Statistic
                precision={2}
                valueStyle={{
                  color: "rgb(63, 134, 0)",
                  fontWeight: "bold",
                  fontSize: 36,
                  textAlign: "center"
                }}
                value={finalTotal.toFixed(2)}
                prefix={
                  <div style={{ fontSize: 30 }}>
                    {/* <RIcon size={48} icon={dollar} /> */}
                    <span style={{ fontSize: '16px', paddingRight:"10px" }}>NOK</span>
                     TOTAL{" "}
                  </div>
                }
              />
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  };
  const renderPage = () => {
    return (
      <>
        <div className="report-container">
          <span className="item ">
            <span>Arkiv : </span>  Z - Repport 
          </span>
            <NavLink to="/arkiv" className="btn back-btn">
              <i className="fa fa-arrow-circle-left" aria-hidden="true" />
            </NavLink>
        </div>
        <Card id="groupReport-card">
          <Card.Body>
            <Row className="m-b-1 justify-content-between">
              {/* <Col lg={4} md="auto" /> */}
              <Col lg={8} md="auto">
                <DatePicker onChange={(e) => handleFilter(e, "d")} />
                <MonthPicker
                  placeholder="Select Month"
                  onChange={(e) => handleFilter(e, "m")}
                />
              </Col>
              <Col lg={2} md="auto" className="text-right">
                <Button
                  className="btn-ab print-btn"
                  style={{ background: "#dcbb68" }}
                  onClick={() => printGPReport()}
                >
                  {printLoader ? (
                    <Spin size="small" />
                  ) : (
                    <i className="fa fa-print"></i>
                  )}
                </Button>
              </Col>
            </Row>
            <Row className="m-b-1">
              <Col span={8}>
                <Card className="gp-box-shadow">
                  <Card.Body>
                    <Statistic
                      title="KONTANT"
                      precision={2}
                      valueStyle={{ color: "#3f8600", fontSize: 30 }}
                      // prefix={<RIcon size={48} icon={dollar} />}
                      prefix={<span style={{ paddingRight:"10px",fontSize: '16px' }}>NOK</span>}
                      value={totalCash}
                    />
                  </Card.Body>
                </Card>
              </Col>
           
              <Col span={8}>
                <Card className="gp-box-shadow">
                  <Card.Body>
                    <Statistic
                      title="EZYGO CARD"
                      precision={2}
                      valueStyle={{ color: "#3f8600", fontSize: 30 }}
                      //prefix={<RIcon size={48} icon={bank} />}
                      prefix={<span style={{ paddingRight:"10px",fontSize: '16px' }}>NOK</span>}
                      value={Number(OrcTotal)+Number(onlineTotal)}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col span={8}>
                <Card className="gp-box-shadow">
                  <Card.Body>
                    <Statistic
                      title="CARD"
                      precision={2}
                      valueStyle={{ color: "#3f8600", fontSize: 30 }}
                      // prefix={<RIcon size={48} icon={bank} />}
                      prefix={<span style={{ paddingRight:"10px",fontSize: '16px' }}>NOK</span>}
                      // value={Number(totalBank)+Number(treeDriveTotal)+Number(onlineTotal)}
                      value={Number(totalBank)+Number(treeDriveTotal)}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {localStorage.getItem("vippsPermission") == "true" ||
            localStorage.getItem("couponPermission") == "true" ||
            localStorage.getItem("isTreeDriveAllowed") ? (
              <Row className="m-b-1">
                {localStorage.getItem("vippsPermission") == "true"
                  ? VippsCard()
                  : ""}
                {localStorage.getItem("couponPermission") == "true"
                  ? CouponCard()
                  : ""}

                {/* {localStorage.getItem("isTreeDriveAllowed") == "true"
                  ? TreeDriveCard()
                  : ""} */}
              </Row>
            ) : (
              ""
            )}

            <Row className="m-b-1">
              <Col span={8}>
                <Card className="gp-box-shadow">
                  <Card.Body className="onlineCard">
                    <Statistic
                      title="KREDIT"
                      precision={2}
                      valueStyle={{ color: "#3f8600", fontSize: 30 }}
                      // prefix={<RIcon size={48} icon={dollar} />}
                      prefix={<span style={{ paddingRight:"10px",fontSize: '16px' }}>NOK</span>}
                      value={totalCredit}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col span={8}>
                <Card className="gp-box-shadow">
                  <Card.Body>
                    <Statistic
                      title="KONTANT BOX RAPPORT"
                      valueStyle={{ color: "#3f8600", fontSize: 30 }}
                      //prefix={<RIcon size={48} icon={fileText} />}
                      prefix={<span style={{ paddingRight:"10px",fontSize: '16px' }}>NOK</span>}
                      value={totalBoxOpen}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col span={8}>
                <Card className="gp-box-shadow">
                  <Card.Body>
                    <Statistic
                      value={totalRefund}
                      title="REFUND"
                      valueStyle={{ color: "#3f8600", fontSize: 30 }}
                      // prefix={<RIcon size={48} icon={dollar} />}
                      prefix={<span style={{ paddingRight:"10px",fontSize: '16px' }}>NOK</span>}
                    />
                  </Card.Body>
                </Card>
              </Col>

            </Row>

<Row>
              {TotallCard()}

</Row>
            <ReportList report={report} />
            {driveOutCharges && Object.keys(driveOutCharges).length != 0 ? (
              <Row className="m-b-1">
                <Col>
                  <Card>
                    <Card.Header>UTKJØRING</Card.Header>
                    <Card.Body>
                      <Table
                        dataSource={driveoutDataSource(driveOutCharges)}
                        columns={driveoutColumns()}
                        pagination={false}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )}
             {/* {couponData &&Object.keys(couponData).length != 0 ? (
              <Row className="m-b-1">
                <Col>
                  <Card>
                    <Card.Header>RESTAURANT COUPONS</Card.Header>
                    <Card.Body>
                      <Table
                        dataSource={couponDataSource(couponData)}
                        columns={couponColumns()}
                        pagination={false}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )} */}
            {/* {reserveTableData &&Object.keys(reserveTableData).length != 0 ? (
              <Row className="m-b-1">
                <Col>
                  <Card>
                    <Card.Header>RESERVE TABLE</Card.Header>
                    <Card.Body>
                      <Table
                        dataSource={reserveTableDataSource(reserveTableData)}
                        columns={reserveTableColumns()}
                        pagination={false}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )} */}

            {tips.length ? (
              <Row className="m-b-1">
                <Col>
                  <Card>
                    <Card.Header>ANSATTE TIP RAPPORT</Card.Header>
                    <Card.Body>{tipList(tips)}</Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {refundData.length ? (
              <Row className="m-b-1">
                <Col>
                  <Card>
                    <Card.Header>RAPPORT FOR REFUSJON AV KUNDE</Card.Header>
                    <Card.Body>{refundList(refundData)}</Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )}
            {unPaid.length ? (
              <Row className="m-b-1">
                <Col>
                  <Card>
                    <Card.Header>KREDIT/FAKTURA (UBETALT)</Card.Header>
                    <Card.Body>{unPaidList(unPaid)}</Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </>
    );
  };
  return <Home>{renderPage()}</Home>;
};
const mapStateToProps = ({ groupReport }) => {
  return {
    groupReport
  };
};
export default connect(mapStateToProps, { getGroupReport, printGroupReport })(
  Index
);
